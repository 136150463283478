
import { Prop, Component, Watch } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';

import DropDownComponent from '@/components/common/Dropdown.vue';

@Component({
  components: {
    Dropdown: DropDownComponent,
  },
})

export default class TasqsListFilteringPopup extends mixins() {
  @Prop({
    type: Array,
    default() {
      return [];
    },
  }) selectedValues!: any;

  @Prop({
    type: Array,
    default() {
      return [];
    },
  }) options!: any;

   @Prop({ type: Boolean, default: false }) hideSelectAllOption!: boolean;

  @Prop({ type: String, default: 'Placeholder' }) placeholder!: string;

  @Prop({ type: String, default: '' }) title!: string;

  @Prop({ type: String, default: '' }) trackBy!: string;

  @Prop({ type: String, default: '' }) label!: string;

  localSelectedValues: any = []

  emitValuesUpdatedEvent() {
    if (JSON.stringify(this.selectedValues) !== JSON.stringify(this.localSelectedValues)) {
      this.$emit('update-selected-values', this.localSelectedValues);
    }
  }

  handleDelete(option) {
    this.localSelectedValues = this.localSelectedValues.filter((item) => item !== option);
    this.$emit('update-selected-values', this.localSelectedValues);
  }

  @Watch('selectedValues', {
      immediate: true,
  })
  async onSelectedValuesChange(value) {
    this.localSelectedValues = value;
  }
}
