
import { Prop, Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import DropdownPlaceholder from '@/components/tasqsCommon/DropdownPlaceholder.vue';

@Component({
  components: {
    DropdownPlaceholder,
  },
})
export default class NotificationFilter extends mixins() {
  @Prop({ type: Array, default: () => [] }) notifications!: any;

  @Prop({ type: Object, default: () => {} }) working!: any;


  localFilterWorking: any = {}

  created() {
    this.localFilterWorking = JSON.parse(JSON.stringify(this.working));
  }
  get filteringOptions() {
    return [
      {
        showCondition: true,
        selectedValues: [],
        eventKey: 'selectedWells',
        options:  [...new Set(this.notifications.map((notification) => notification.nodeName))],
        title: 'WELLS',
        hideSelectAllOptions: true,
        placeholder: 'Select Wells',
      },

      {
        showCondition: true,
        selectedValues: [],
        eventKey: 'selectedJobTypes',
        options:[...new Set(this.notifications.map((notification) => notification.predictionType))],
        title: 'JOB TYPES',
        hideSelectAllOptions: false,
        placeholder: 'Select Job Types',
      },
    ];
  }

  saveFiltering(event,option){
    this.$emit('filtering-updates', this.localFilterWorking)
  }
}
